@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  font-family: 'Kalam', sans-serif;
  margin: 0;
  padding: 0;
  background-image: url('/src/assets/bg-static.jpg');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  background-attachment: fixed;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 50px;
}
.join-us {
  color: #fff;
  display: flex;
  text-align: center;
  font-size: 100px;
  font-weight: 700;
  transform: rotateZ(6deg);
  overflow: hidden;
}

.logo {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
}

.navigation {
  width: 60vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  overflow: hidden;
}
.nav_button {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

.text {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
}
.t1 {
  display: flex;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}
.comic {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  padding-bottom: 50px;
  transform-origin: center;
}
.comic-container {
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}

.dex {
  display: flex;
  justify-content: center;
}
#dexscreener-embed {
  position: relative;
  width: 80vw;
  padding-bottom: 125%;
}
@media (min-width: 1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}
#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

@media only screen and (max-width: 600px) {
  .text {
    font-size: 15px;
  }
  .t1 {
    font-size: 20px;
  }
  .navigation {
    width: 100vw;
  }
  .join-us {
    font-size: 50px;
  }
}
